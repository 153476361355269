import React from 'react';
import ReactDOM from "react-dom/client";
import './styling/base.css';
import MainRouter from './Router';
import registerServiceWorker from './registerServiceWorker';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <MainRouter />
  </React.StrictMode>
);
registerServiceWorker();
