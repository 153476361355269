import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import { Billboard } from '../Billboard';
import { ContactForm } from '../ContactForm';

class Careers extends Component {

  render() {
    const build = this.props.build;

    const metaDescription = `Jobs ${build["name"]}.`;
    const title = `Jobs at ${build["name"]}`;

    const phoneLink = 'tel:+1' + build["phone"].split(' ').join('');
    const emailLink = "mailto:" + build["email"];

    return (
      <div className="main-content">
        <Helmet>
            <meta name="description" content={metaDescription} />
            <meta name="keywords" content={`jobs at ${build["name"]}`} />
            <title>{title}</title>
        </Helmet>


        <Billboard
          image="./images/backgrounds/careers.jpg"
          horizontal="center"
          vertical="middle"
          width="three-quarters"
          colour="light"
          alignText="center"
          mini
          className="contact-board"
        >
            <h1>Join the True Home<sup>&#174;</sup> Team</h1>
        </Billboard>

        <div className="contact-info" style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <div className="text-info" style={{ maxWidth: "600px", lineHeight: "1.5" }}>
            <h2>Are you a construction rock star?</h2>
            <h2>We want to talk to you!</h2>
            <br />
            If you are a person who comes to work each day with a great attitude, a willingness to learn, and the drive to do your best – you’re just the candidate we’re looking for. Whether you already live in our service areas or you’re open to a great opportunity in a new region or province, you’ll be a welcome addition to our team.
            <br />
            <br />
            We’ve built an amazing team that does quality work and has fun doing it! We’re looking for awesome people to join in, dive in and help us continue to delight and amaze our clients.
            <br />
            <br />
            <br />
            <span className="info-title">Submit your résumé here:</span>
            <a href={emailLink}>{build["email"]}</a>
          </div>
        </div>
      </div>
    );
  }
}

export default Careers;
