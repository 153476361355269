import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button } from '../Buttons';

class ContactForm extends Component {

  static propTypes = {
    colour: PropTypes.bool,
    className: PropTypes.string,
    buttonsOnly: PropTypes.bool,
    email: PropTypes.string,
    phone: PropTypes.string
  };

  render() {
    const {
      children,
      colour,
      className,
      buttonsOnly,
      email,
      phone
    } = this.props;

    const contactClass = classNames(
        'contact',
        {
          'colour': colour
        },
        className
    );

    const phoneLink = 'tel:+1' + phone.split(' ').join('');
    const emailLink = "mailto:" + email;

    if (buttonsOnly) {
      return (
        <section className={contactClass}>
          <header>
            {children ?
              children
              :
              <h2>Contact us for a free quote today!</h2>
            }
          </header>

          <div className="form-action-container">
            <Button
              type='line'
              className='submit-phone'
              href="tel:+12505169046"
            >
              250 516 9046
            </Button>

            {colour ?
              <Button
                type='line'
                className='submit-phone'
                light
                href={phoneLink}
              >
                {phone}
              </Button>
              :
              <Button
                type='line'
                className='submit-phone'
                href={phoneLink}
              >
                {phone}
              </Button>
            }

            {colour ?
              <Button
                type='line'
                className='submit-email'
                light
                href={emailLink}
              >
                Email Us Now
              </Button>
              :
              <Button
                type='line'
                className='submit-email'
                href={emailLink}
              >
                Email Us Now
              </Button>
            }
          </div>
        </section>
      );
    } else {
      return (
        <section className={contactClass}>
          <header>
            {children ?
              children
              :
              <h2>Contact us for a free quote today!</h2>
            }
          </header>
          <form>
            <div className='personal'>
              <label htmlFor='name'>Name</label>
              <input type="text" id='name' name="name" />
              <label htmlFor='email'>Email</label>
              <input type="email" id='email' name="email" />
            </div>
            <div className='message-container'>
              <label htmlFor='message'>Message</label>
              <textarea id='message' name="message" rows="7" />
            </div>
          </form>

          {colour ?
            <Button
              type='line'
              light
              className="submit"
            >
              Submit
            </Button>
            :
            <Button
              type='line'
              className="submit"
            >
              Submit
            </Button>
          }
        </section>
      );
    }
  }
}

export default ContactForm;
