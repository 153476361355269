import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Paper from 'material-ui/Paper';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

// Long-term goal is to actually pull live or selective revies onto the website
// There needs to be filtering system of course
// We can actually pull reviews from googl api
// https://developers.google.com/my-business/content/review-data

class Review extends Component {

  static propTypes = {
    author: PropTypes.string,
    rating: PropTypes.number,
    source: PropTypes.string,
    platform: PropTypes.string,
    body: PropTypes.string
  };

  render() {

    const {
      author,
      body,
      rating,
      source,
      platform
    } = this.props;

    const paperStyle = {
      margin: '0 8px 16px 8px',
      padding: 0,
      textAlign: 'left',
      position: 'relative',
      display: 'inline-block'
    };

    // Handle if there is no source
    let sourceLink;
    source ? sourceLink = source : sourceLink = "#";

    // Handle rating
    // Needs to fill out only certain amount of stars
    let stars=[];
    for (let i = 0; i < 5; i++) {
      stars.push(<i className='star material-icons'>star</i>);
    }
    if (rating) {
      for (let i = 4; i >= rating; i--) {
        stars[i] = <i className='star material-icons'>star_border</i>;
      }
    }

    // Handle platform
    let reviewPlatform;
    platform ? reviewPlatform = 'view on ' + platform
             : reviewPlatform = 'view origin';

    // Handle body
    let reviewBody;
    body.length > 240 ? reviewBody = body.substring(0,237) + '...'
                      : reviewBody = body;

    return (
      <section className="review">
        <MuiThemeProvider>
          <Paper style={paperStyle} zDepth={2}>
            <div className="content">
              <header>
                  <h3 className="author">{author}</h3>
                  <div className="rating">{stars}</div>
              </header>
              <p className="body">{reviewBody}</p>
              <a
                className="link-to-origin"
                href={source}
              >
                {reviewPlatform}
              </a>
            </div>
          </Paper>
        </MuiThemeProvider>
      </section>
    );
  }
}

export default Review;
