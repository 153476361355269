module.exports = {
    commercial: [
        {
            title: "Dunford",
            gallery: [
                {
                    original: './images/portfolio/commercial/dunford/true_home_dunford_1.jpg'
                },
                {
                    original: './images/portfolio/commercial/dunford/true_home_dunford_2.jpg'
                },
                {
                    original: './images/portfolio/commercial/dunford/true_home_dunford_3.jpg'
                },
                {
                    original: './images/portfolio/commercial/dunford/true_home_dunford_4.jpg'
                },
                {
                    original: './images/portfolio/commercial/dunford/true_home_dunford_5.jpg'
                },
                {
                    original: './images/portfolio/commercial/dunford/true_home_dunford_6.jpg'
                },
                {
                    original: './images/portfolio/commercial/dunford/true_home_dunford_7.jpg'
                },
                {
                    original: './images/portfolio/commercial/dunford/true_home_dunford_8.jpg'
                },
                {
                    original: './images/portfolio/commercial/dunford/true_home_dunford_9.jpg'
                },
                {
                    original: './images/portfolio/commercial/dunford/true_home_dunford_10.jpg'
                },
                {
                    original: './images/portfolio/commercial/dunford/true_home_dunford_11.jpg'
                },
                {
                    original: './images/portfolio/commercial/dunford/true_home_dunford_12.jpg'
                },
                {
                    original: './images/portfolio/commercial/dunford/true_home_dunford_13.jpg'
                },
                {
                    original: './images/portfolio/commercial/dunford/true_home_dunford_14.jpg'
                },
                {
                    original: './images/portfolio/commercial/dunford/true_home_dunford_15.jpg'
                },
                {
                    original: './images/portfolio/commercial/dunford/true_home_dunford_16.jpg'
                },
                {
                    original: './images/portfolio/commercial/dunford/true_home_dunford_17.jpg'
                },
                {
                    original: './images/portfolio/commercial/dunford/true_home_dunford_18.jpg'
                },
                {
                    original: './images/portfolio/commercial/dunford/true_home_dunford_19.jpg'
                },
                {
                    original: './images/portfolio/commercial/dunford/true_home_dunford_20.jpg'
                },
                {
                    original: './images/portfolio/commercial/dunford/true_home_dunford_21.jpg'
                },
                {
                    original: './images/portfolio/commercial/dunford/true_home_dunford_22.jpg'
                },
                {
                    original: './images/portfolio/commercial/dunford/true_home_dunford_20.jpg'
                },
            ]
        },
        {
            title: "Douglas",
            gallery: [
                {
                    original: './images/portfolio/commercial/douglas/true_home_douglas_1.jpg'
                },
                {
                    original: './images/portfolio/commercial/douglas/true_home_douglas_2.jpg'
                },
                {
                    original: './images/portfolio/commercial/douglas/true_home_douglas_3.jpg'
                },
                {
                    original: './images/portfolio/commercial/douglas/true_home_douglas_4.jpg'
                },
                {
                    original: './images/portfolio/commercial/douglas/true_home_douglas_5.jpg'
                },
                {
                    original: './images/portfolio/commercial/douglas/true_home_douglas_6.jpg'
                },
                {
                    original: './images/portfolio/commercial/douglas/true_home_douglas_7.jpg'
                },
                {
                    original: './images/portfolio/commercial/douglas/true_home_douglas_8.jpg'
                },
                {
                    original: './images/portfolio/commercial/douglas/true_home_douglas_9.jpg'
                },
                {
                    original: './images/portfolio/commercial/douglas/true_home_douglas_10.jpg'
                },
                {
                    original: './images/portfolio/commercial/douglas/true_home_douglas_11.jpg'
                },
                {
                    original: './images/portfolio/commercial/douglas/true_home_douglas_12.jpg'
                },
                {
                    original: './images/portfolio/commercial/douglas/true_home_douglas_13.jpg'
                },
            ]
        },
        {
            title: "Jacklin Rd",
            gallery: [
                {
                    original: './images/portfolio/commercial/jacklin_road/true_home_jacklin_road_1.jpg'
                },
                {
                    original: './images/portfolio/commercial/jacklin_road/true_home_jacklin_road_2.jpg'
                },
                {
                    original: './images/portfolio/commercial/jacklin_road/true_home_jacklin_road_3.jpg'
                },
                {
                    original: './images/portfolio/commercial/jacklin_road/true_home_jacklin_road_4.jpg'
                },
                {
                    original: './images/portfolio/commercial/jacklin_road/true_home_jacklin_road_28.jpg'
                },
                {
                    original: './images/portfolio/commercial/jacklin_road/true_home_jacklin_road_29.jpg'
                },
                {
                    original: './images/portfolio/commercial/jacklin_road/true_home_jacklin_road_30.jpg'
                },
                {
                    original: './images/portfolio/commercial/jacklin_road/true_home_jacklin_road_5.jpg'
                },
                {
                    original: './images/portfolio/commercial/jacklin_road/true_home_jacklin_road_6.jpg'
                },
                {
                    original: './images/portfolio/commercial/jacklin_road/true_home_jacklin_road_7.jpg'
                },
                {
                    original: './images/portfolio/commercial/jacklin_road/true_home_jacklin_road_8.jpg'
                },
                {
                    original: './images/portfolio/commercial/jacklin_road/true_home_jacklin_road_9.jpg'
                },
                {
                    original: './images/portfolio/commercial/jacklin_road/true_home_jacklin_road_10.jpg'
                },
                {
                    original: './images/portfolio/commercial/jacklin_road/true_home_jacklin_road_11.jpg'
                },
                {
                    original: './images/portfolio/commercial/jacklin_road/true_home_jacklin_road_12.jpg'
                },
                {
                    original: './images/portfolio/commercial/jacklin_road/true_home_jacklin_road_13.jpg'
                },
                {
                    original: './images/portfolio/commercial/jacklin_road/true_home_jacklin_road_14.jpg'
                },
                {
                    original: './images/portfolio/commercial/jacklin_road/true_home_jacklin_road_15.jpg'
                },
                {
                    original: './images/portfolio/commercial/jacklin_road/true_home_jacklin_road_16.jpg'
                },
                {
                    original: './images/portfolio/commercial/jacklin_road/true_home_jacklin_road_17.jpg'
                },
                {
                    original: './images/portfolio/commercial/jacklin_road/true_home_jacklin_road_18.jpg'
                },
                {
                    original: './images/portfolio/commercial/jacklin_road/true_home_jacklin_road_19.jpg'
                },
                {
                    original: './images/portfolio/commercial/jacklin_road/true_home_jacklin_road_20.jpg'
                },
                {
                    original: './images/portfolio/commercial/jacklin_road/true_home_jacklin_road_21.jpg'
                },
                {
                    original: './images/portfolio/commercial/jacklin_road/true_home_jacklin_road_22.jpg'
                },
                {
                    original: './images/portfolio/commercial/jacklin_road/true_home_jacklin_road_23.jpg'
                },
                {
                    original: './images/portfolio/commercial/jacklin_road/true_home_jacklin_road_24.jpg'
                },
                {
                    original: './images/portfolio/commercial/jacklin_road/true_home_jacklin_road_25.jpg'
                },
                {
                    original: './images/portfolio/commercial/jacklin_road/true_home_jacklin_road_26.jpg'
                },
                {
                    original: './images/portfolio/commercial/jacklin_road/true_home_jacklin_road_27.jpg'
                },
            ]
        },
    ]
}
