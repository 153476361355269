import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import { Billboard } from '../Billboard';

class AboutUs extends Component {
  render() {
    const { build } = this.props;

    const metaDescription = `Get to know ${build["name"]} a little better.`;
    const title = `About ${build["location"]}'s ${build["name"]}`;

    return (
      <div className="main-content">
        <Helmet>
            <meta name="description" content={metaDescription} />
            <meta name="keywords" content={build["name"]} />
            <title>{title}</title>
        </Helmet>

        <Billboard
          image="./images/backgrounds/about.jpg"
          horizontal="center"
          vertical="middle"
          width="three-quarters"
          colour="light"
          alignText="center"
          mini
          className="about-board"
        >
            <h1>
              Learn More About
              <br/>
              {build["name"]}
            </h1>
        </Billboard>

        <div className="about-container">
          <p className="about-info">
            Established in 2009 by Red Seal carpenter Paul Greenwood, True Home<sup>&#174;</sup> Construction grew quickly from a small carpentry business to a general construction company.  Capable today of accommodating a wide range of budget requirements and site conditions, True Home® specializes in residential and commercial renovations, also boasting a Residential Builder’s License, permitting new residential construction anywhere in BC.  In addition, Paul is a licensed Realtor, enabling him to have a better understanding of the impact his work has on the value of residential properties and making him an invaluable asset to buyers and sellers.
            <br />
            <br />
            Here at True Home<sup>&#174;</sup>, our mission is to realize your vision with quality workmanship and efficiency.  We are proud to belong to a number of organizations that promote ethical building practices in BC and through the rest of Canada.  We are happily an Accredited Business through the Better Business Bureau, a member of the  Homeowners Protection Office, and are backed by National Home Warranty, providing a 2-5-10 year warranty on all new homes we build.  We also have excellent standing with our WCB coverage and carry third-party, 5 million dollar liability insurance on all jobs.
            <br />
            <br />
            With an emphasis on involvement and punctuality, True Home<sup>&#174;</sup> Construction continues to offer superior customer satisfaction throughout Greater Victoria.  Please click <a href="/services"><b>here</b></a> for a complete list of available services.
          </p>
        </div>
        <h2 className="team-gallery-title">Meet our Team</h2>
        <section className="sections-container about-us-gallery">
          <div className="teammate-image-container">
            <img
              src="./images/about_us/paul.jpg"
              className="teammate-image"
            />
            <p><b>Owner:</b> Paul Greenwood</p>
          </div>
          <div className="teammate-image-container">
            <img
              src="./images/about_us/ian.jpg"
              className="teammate-image"
            />
            <p><b>Senior Project Manager and Red Seal Carpenter:</b> Ian Greenwood</p>
          </div>
          <div className="teammate-image-container">
            <img
              src="./images/about_us/jesse.jpg"
              className="teammate-image"
            />
            <p><b>Project Manager and Carpenter:</b> Jesse Esplen</p>
          </div>
          <div className="teammate-image-container">
            <img
              src="./images/about_us/jasmine.jpg"
              className="teammate-image"
            />
            <p><b>Office Manager:</b> Jasmine Blais</p>
          </div>
        </section>
        <div className="address-info" style={{ marginBottom: "56px", margin: "16px 16px 56px" }}>
          <h3 className="map-title">Interested in joining our crew? Checkout our <a href="/careers" style={{ cursor: "pointer", textDecoration: "underline", fontSize: "24px", fontWeight: 300 }}>careers page</a></h3>
        </div>
      </div>
    );
  }
}

export default AboutUs;
