// React router set up
import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ScrollToTop from "./ScrollToTop";

import App from './App';

class MainRouter extends Component {
  render() {
    return (
      <Router>
        <ScrollToTop />
        <App />
      </Router>
    );
  }
}


export default MainRouter;
