import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import { Button } from '../Buttons';
import { Billboard } from '../Billboard';
import { SectionsContainer, Section } from '../Sections';


class Services extends Component {
  render() {
    const { build } = this.props;

    const metaDescription = `Custom house building by ${build["name"]}.`;
    const title = `Custom house building by ${build["name"]}`;

    let content = [];
    let blocks = [];
    let counter = 0;

    for (var service in build["services"]) {
      blocks.push(
        <Section
          icon={build["services"][service]["icon"]}
          title={build["services"][service]["name"]}
          mini
          link={"/services/" + service}
          primaryColour={build["services"][service]["highlight"]}
        />
      );
    }

    // Slice blocks into sets of 5
    while (counter < blocks.length) {
      content.push(
        <SectionsContainer stack>
          {blocks.slice(counter, counter + 5)}
        </SectionsContainer>
      );
      counter += 5;
    }


    return (
      <div className='main-content'>
        <Helmet>
            <meta name="description" content={metaDescription} />
            <meta name="keywords" content={`plumbing by ${build["name"]}`} />
            <title>{title}</title>
        </Helmet>

        <Billboard
          image="./images/backgrounds/services.jpg"
          horizontal="center"
          vertical="middle"
          width="full"
          colour="light"
          alignText="center"
          mini
          className="services"
        >
          <h1>Our Services</h1>
          <p>We are experts in all areas of construction. Click on one of our services to learn more.</p>
          <Button
            type="line"
            href="./contact"
            className="book-service"
            small
            light
            >
              Schedule Free Service Estimate
          </Button>
        </Billboard>

        {content}
      </div>
    );
  }
}

export default Services;
