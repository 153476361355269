import React, { Component } from 'react';

class Logo extends Component {

  render() {

    const {
      input,
      logoSecondaryText
    } = this.props;

    // Check Logo Type
    const logoSrc = () => {
        let logoType;
        // If SVG or PNG
        if (/(\.svg)$|(\.png)$/.test(input)) {
            logoType = <img className="logo" src={input} />;
        } else {
            // If String
            logoType = input;
        }
        return logoType;
    };


    return (
        <div className="logo">
          <a href="/">
            <img
              src="../images/true_home_construction_logo.png"
              className="primary-image"
            />
          </a>
        </div>
    );
  }
}

export default Logo;
