import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class Billboard extends Component {

  static propTypes = {
    link: PropTypes.string,
    apiKey: PropTypes.string
  };

  render() {
    const {
      link,
      apiKey
    } = this.props;

    const src = 'https://www.google.com/maps/embed/v1/place?q=' + link + '&zoom=12&key=' + apiKey;

    return (
      <section className='map-container'>
        <iframe
          className='map'
          src={src}>
        </iframe>
      </section>
    );
  }
}

export default Billboard;
