import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Routes, Route, Navigate } from 'react-router-dom';

import Home from '../Pages/Home';
import AboutUs from '../Pages/AboutUs';
import Services from '../Pages/Services';
import Service from '../Pages/Service';
import Contact from '../Pages/Contact';
import Portfolio from '../Pages/Portfolio';
import Careers from '../Pages/Careers';
import Reviews from '../Pages/Reviews';
import Page404 from '../Pages/404';

class MainContent extends Component {

  static propTypes = { build: PropTypes.object };

  render() {
    const { build } = this.props;

    return (
      <main>
        <Routes>
          <Route path='/home' element={<Home build={build} />} />

          {/* Handle each Service page  */}
          <Route path='/services'>
            <Route index element={<Services build={build} />} />
            <Route path=':serviceName' element={<Service build={build} />} />
          </Route>

          {/* Static Pages */}
          <Route path='/portfolio' element={<Portfolio build={build} />} />
          <Route path='/about' element={<AboutUs build={build} />} />
          <Route path='/contact' element={<Contact build={build} />} />
          <Route path='/careers' element={<Careers build={build} />} />
          <Route path='/reviews' element={<Reviews build={build} />} />

          <Route
            path="/"
            element={<Navigate to="/home" replace />}
          />

          {/* 404 */}
          <Route path='*' element={<Page404 />} />
        </Routes>
      </main>
    );
  }
}

export default MainContent;
