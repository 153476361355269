import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import MobileMenu from './MobileMenu';
import Pages from './Pages';
import Logo from './Logo';
import Phone from './Phone';


class TopNav extends Component {

  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    logo: PropTypes.string, // takes a string and checks for image vs text
    logoSecondaryText: PropTypes.string, // adds smaller secondary text to logo
    children: PropTypes.node,
    pages: PropTypes.array.isRequired,
    phone: PropTypes.string
  };

  render() {

    const {
      className,
      style,
      logo,
      logoSecondaryText,
      children,
      pages,
      phone
    } = this.props;

    const componentClass = classNames('top-nav', className);

    return (
      <nav className={componentClass} style={style}>
        <MobileMenu pages={pages} />
        <Logo
          input={logo}
          logoSecondaryText={logoSecondaryText}
        />
        <Pages pages={pages} />
        <Phone phone={phone} />
      </nav>
    );
  }
}

export default TopNav;
