import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Review from './Review';


// Long-term goal is to actually pull live or selective revies onto the website
// There needs to be filtering system of course
// We can actually pull reviews from googl api
// https://developers.google.com/my-business/content/review-data

class ReviewsContainer extends Component {

  static propTypes = {
    title: PropTypes.string,
    reviews: PropTypes.array
  };

  render() {

    const {
      title,
      children,
      reviews
    } = this.props;

    let buildReviews;
    if (reviews) {
      buildReviews = reviews.map((review) => {
        return (
          <Review
            author={review["author"]}
            rating={review["rating"]}
            platform={review["platform"]}
            source={review["source"]}
            body={review["body"]}
          />
        );
      });
    }

    return (
      <section className="reviews">
        <header>
            <h2 className="title">{title}</h2>
        </header>
        <div
            className="reviews-container"
            style={{ marginBottom: "56px" }}
        >
          { buildReviews ?
            buildReviews
            :
            children
          }
        </div>
        <h3>
          <a
            style={{ marginBottom: "48px", cursor: "pointer", textDecoration: "underline", fontSize: "24px", fontWeight: 300, color: "white" }}
            href="https://search.google.com/local/writereview?placeid=ChIJg7_CuUltj1QRzo_EHz4MMlI"
            target="_blank"
          >
            Write Us A Review
          </a>
        </h3>
      </section>
    );
  }
}

export default ReviewsContainer;
