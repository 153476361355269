import React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from "react-router-dom";

import { Billboard } from '../Billboard';
import { ContactForm } from '../ContactForm';
import Page404 from './404';

import renderHTML from 'react-render-html';

const Service = (props) => {
  const { build } = props;

  const { serviceName } = useParams();

  const matchedService = serviceName;

  if (build["services"][matchedService]) {
    const service = build["services"][matchedService];
    let billboardImage;

    const metaDescription = `${service["title"]} in ${build["location"]}.`;
    const keywords = `${service["title"]} in ${build["location"]}`;
    const title = `${service["title"]} in ${build["location"]}`;

    service["billboard_image"] ?
        billboardImage = "../" + service["billboard_image"]
        :
        billboardImage = false;

    return (
      <div className='main-content'>
        <Helmet>
            <meta name="description" content={metaDescription} />
            <meta name="keywords" content={keywords} />
            <title>{title}</title>
        </Helmet>

        <Billboard
          image={billboardImage}
          horizontal="center"
          vertical="middle"
          width="half"
          colour="dark"
          alignText="center"
          mini
        >
          <h1>{`${service["title"]} in ${build["location"]}`}</h1>
        </Billboard>

        <div className='service-container'>
          {renderHTML(service["body"])}
        </div>

        <ContactForm
          phone={build["phone"]}
          email={build["email"]}
          buttonsOnly
        >
          <h2>Let Us Help You Today! Or See Other Services We Provide <a href="/services" style={{ fontSize: "32px", fontWeight: "400", textDecoration: "underline" }}>Here</a></h2>
        </ContactForm>
      </div>
    );
  } else {
    return <Page404 />;
  }
}

export default Service;
