import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import { TopNav, MainContent, BottomNav, ContactButton } from './components';

import { build } from './config';

class App extends Component {
  render() {
    return (
      <div className="App">
        <Helmet>
            <meta name="description" content={build["meta"]["description"]} />
            <meta name="keywords" content={build["meta"]["keywords"]} />
            <title>{`${build["name"]}, plumbers in ${build["location"]}`}</title>
        </Helmet>

        <TopNav
          logo={build["logo"]}
          logoSecondaryText="construction"
          phone={build["phone"]}
          pages={build["pages"]}
          >
        </TopNav>

        <ContactButton
          phone={build["phone"]}
          email={build["email"]}
        />

        <MainContent
          build={build}
        />

        <BottomNav
          build={build}
        />
      </div>
    );
  }
}

export default App;
