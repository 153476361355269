import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Section from './Section';

class SectionsContainer extends Component {

  static propTypes = {
    style: PropTypes.object
  };

  render() {

    const {
      style,
      children,
      stack,
      sections
     } = this.props;

     let componentClass = "sections-container";
     if (stack) {
       componentClass += " stack"
     }

     let buildSections;
     if (sections) {
       buildSections = sections.map((section) => {
         return (
           <Section
             icon={section["icon"]}
             title={section["title"]}
             body={section["body"]}
           />
         );
       });
     }

    return (
      <section
        className={componentClass}
        style={style}
      >
        { sections ?
          buildSections
          :
          children
        }
      </section>
    );
  }
}

export default SectionsContainer;
