import React, { Component } from 'react';

import { Button } from '../Buttons';

class Phone extends Component {

  render() {
    const { phone } = this.props;

    const phoneLink = 'tel:+1' + phone.split(' ').join('');

    return (

      <div className="phone">
        <Button
          small
          light
          type="quiet"
          className="call-icon"
          href={phoneLink}
          icon="phone"
        />

        <Button
          small
          light
          type="line"
          className="number"
          href={phoneLink}
        >{phone}</Button>
      </div>
    );
  }
}

export default Phone;
