import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import ImageGallery from 'react-image-gallery';

import { Billboard } from '../Billboard';
import { Storyboard } from '../Storyboard';
import { ReviewsContainer } from '../Reviews';
import { SectionsContainer } from '../Sections';
import { Map } from '../Map';
import { Clubs } from '../Clubs';
import { ContactForm } from '../ContactForm';
import { CodIcon } from '../CodIcon';
import { Button } from '../Buttons';

const gallery = [
  {
    original: './images/home_page/true_home_construction_intro_banner_1.jpg'
  },
  {
    original: './images/home_page/true_home_construction_intro_banner_2.jpg'
  },
  {
    original: './images/home_page/true_home_construction_intro_banner_3.jpg'
  },
  {
    original: './images/home_page/true_home_construction_intro_banner_4.jpg'
  },
  {
    original: './images/home_page/true_home_construction_intro_banner_5.jpg'
  },
]

class Home extends Component {

  render() {
    const { build } = this.props;

    return (
      <div className='main-content'>
        <Helmet>
            <meta name="description" content={build["meta"]["description"]} />
            <meta name="keywords" content={build["meta"]["keywords"]} />
            <title>{`${build["name"]} in ${build["location"]}`}</title>
        </Helmet>

        <div className="intro-slider-container">
            <div className="gallery-container">
                <ImageGallery
                    items={gallery}
                    showThumbnails={false}
                    autoPlay={true}
                    // showNav={false}
                    showFullscreenButton={false}
                    showPlayButton={false}
                    className="gallery-container"
                    slideInterval={2700}
                    // lazyLoad={true}
                />
            </div>
        </div>

        {/*
            <Billboard
              image={build["homepage"]["billboard"]["image"]}
              horizontal="center"
              vertical="middle"
              width="three-quarters"
              alignText="center"
              colour="light"
            >
              <h1>Custom Homes / Contracting / Renovations</h1>
              <h3>commercial & residential</h3>
            </Billboard>
        */}

        <Storyboard
          title={build["homepage"]["storyboard"]["title"]}
          body={build["homepage"]["storyboard"]["body"]}
          overlapTop
        />

        <SectionsContainer sections={build["homepage"]["sections"]["block_1"]} />


        { build["homepage"]["reviews"] &&
          <ReviewsContainer
            title="Read our glowing reviews"
            reviews={build["homepage"]["reviews"]}
          />
        }

        <Billboard
          image="./images/backgrounds/portfolio-intro.jpg"
          horizontal="center"
          vertical="middle"
          width="half"
          alignText="center"
          colour="light"
          mini
          className="portfolio"
        >
          <h2 className="title">Check Out Our Previous Projects</h2>
          <Button
            type='line'
            light
            href='/portfolio'
          >
            Portfolio
          </Button>
        </Billboard>

        {/* <Billboard
          image="./images/backgrounds/home-lose-weight.jpg"
          horizontal="right"
          vertical="middle"
          width="half"
          alignText="right"
          colour="light"
          mini
        >
          <div className="background-container dark">
            <h2>Lose Weight Program</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris magna lectus, cursus non leo et, semper egestas quam.</p>
            <Button
              type='line'
              light
              small
              href={"/lose-weight-" + build["location"]}
            >
              Learn More
            </Button>
          </div>
        </Billboard> */}

        <div className="address-info">
          <h3 className="map-title">Find us in {build["location"]} BC</h3>
          <i className="material-icons icon-graphic location">location_on</i>
        </div>
        {/*<div className="contact-info">
           <span className="info-title">{build["address"]}</span>
        </div>*/}

        <Map
          link={build["homepage"]["map"]["link"]}
          apiKey={build["homepage"]["map"]["apiKey"]}
        />

        <ContactForm
          phone={build["phone"]}
          email={build["email"]}
          buttonsOnly
        >
          <h2>Contact us for a free quote today!</h2>
          <p>
            We will respond within <b>24hr</b> during the business week.
            <br/>
            You can also request a callback or contact us by sending an email.
            <br/>
            Please don't forget to <b>include your phone number</b> if you would like for us to call you back.
          </p>
        </ContactForm>

        <Clubs
          // bbb={build["homepage"]["clubs"]["bbb"]}
          hpo
          nhm
          // shoplocal
          redseal
          // bni={build["homepage"]["clubs"]["bni"]}
          // bccc={build["homepage"]["clubs"]["bccc"]}
          // cca={build["homepage"]["clubs"]["cca"]}
        />
      </div>
    );
  }
}

export default Home;
