import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class Billboard extends Component {

  static propTypes = {
    horizontal: PropTypes.oneOf([
        'left',
        'center',
        'right'
    ]),
    vertical: PropTypes.oneOf([
        'top',
        'middle',
        'bottom'
    ]),
    width: PropTypes.oneOf([
        'half',
        'three-quarters',
        'full'
    ]),
    colour: PropTypes.oneOf([
        'light',
        'dark'
    ]),
    alignText: PropTypes.oneOf([
        'left',
        'center',
        'right'
    ]),
    image: PropTypes.string,
    mini: PropTypes.bool,
    className: PropTypes.string
  };

  render() {
    const {
      horizontal,
      vertical,
      width,
      colour,
      alignText,
      image,
      mini,
      children,
      className
    } = this.props;

    const billboardClass = classNames(
        'billboard',
        {
          'mini': mini
        },
        horizontal,
        vertical,
        className
    );

    const headerClass = classNames(
        'slogan-container',
        width,
        colour,
        'text-' + alignText
    );

    let style;
    if (image) {
      style = {
        background: `url(${image}) no-repeat center`,
        backgroundSize: 'cover' };
    }

    return (
      <section
        className={billboardClass}
        style={style}
      >
        <header className={headerClass}>
          {children}
        </header>
      </section>
    );
  }
}

export default Billboard;
