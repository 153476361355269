module.exports = {
    residential: [
        {
            title: "Adanac",
            // subtitle: "A store front and interior renovation for a local Victoria business",
            // description: "This project consisted of several different components, both interior and exterior. Inside we installed new interior doors throughout, followed by new engineered maple hardwood throughout the lower level and on all the stairs treads and risers leading to the upstairs. At the same time, we redesigned the banister in fir with a housed stinger. And Lastly, we installed all new window and door casings, and baseboard. On the exterior we installed all new vinyl windows, and sliding glass doors, along with new trim around each. And out of pressure treated lumber we designed and built a new front porch, doubling it in size.",
            gallery: [
                {
                    original: './images/portfolio/residential/adanac/true_home_adanac_32.jpg'
                },
                {
                    original: './images/portfolio/residential/adanac/true_home_adanac_25.jpg'
                },
                {
                    original: './images/portfolio/residential/adanac/true_home_adanac_23.jpg'
                },
                {
                    original: './images/portfolio/residential/adanac/true_home_adanac_24.jpg'
                },
                {
                    original: './images/portfolio/residential/adanac/true_home_adanac_22.jpg'
                },
                {
                    original: './images/portfolio/residential/adanac/true_home_adanac_1.jpg'
                },
                {
                    original: './images/portfolio/residential/adanac/true_home_adanac_2.jpg'
                },
                {
                    original: './images/portfolio/residential/adanac/true_home_adanac_3.jpg'
                },
                {
                    original: './images/portfolio/residential/adanac/true_home_adanac_4.jpg'
                },
                {
                    original: './images/portfolio/residential/adanac/true_home_adanac_5.jpg'
                },
                {
                    original: './images/portfolio/residential/adanac/true_home_adanac_6.jpg'
                },
                {
                    original: './images/portfolio/residential/adanac/true_home_adanac_7.jpg'
                },
                {
                    original: './images/portfolio/residential/adanac/true_home_adanac_8.jpg'
                },
                {
                    original: './images/portfolio/residential/adanac/true_home_adanac_9.jpg'
                },
                {
                    original: './images/portfolio/residential/adanac/true_home_adanac_10.jpg'
                },
                {
                    original: './images/portfolio/residential/adanac/true_home_adanac_11.jpg'
                },
                {
                    original: './images/portfolio/residential/adanac/true_home_adanac_12.jpg'
                },
                {
                    original: './images/portfolio/residential/adanac/true_home_adanac_13.jpg'
                },
                {
                    original: './images/portfolio/residential/adanac/true_home_adanac_14.jpg'
                },
                {
                    original: './images/portfolio/residential/adanac/true_home_adanac_15.jpg'
                },
                {
                    original: './images/portfolio/residential/adanac/true_home_adanac_16.jpg'
                },
                {
                    original: './images/portfolio/residential/adanac/true_home_adanac_17.jpg'
                },
                {
                    original: './images/portfolio/residential/adanac/true_home_adanac_18.jpg'
                },
                {
                    original: './images/portfolio/residential/adanac/true_home_adanac_19.jpg'
                },
                {
                    original: './images/portfolio/residential/adanac/true_home_adanac_20.jpg'
                },
                {
                    original: './images/portfolio/residential/adanac/true_home_adanac_21.jpg'
                },
                {
                    original: './images/portfolio/residential/adanac/true_home_adanac_26.jpg'
                },
                {
                    original: './images/portfolio/residential/adanac/true_home_adanac_27.jpg'
                },
                {
                    original: './images/portfolio/residential/adanac/true_home_adanac_28.jpg'
                },
                {
                    original: './images/portfolio/residential/adanac/true_home_adanac_29.jpg'
                },
                {
                    original: './images/portfolio/residential/adanac/true_home_adanac_30.jpg'
                },
                {
                    original: './images/portfolio/residential/adanac/true_home_adanac_31.jpg'
                },
                {
                    original: './images/portfolio/residential/adanac/true_home_adanac_33.jpg'
                },
                {
                    original: './images/portfolio/residential/adanac/true_home_adanac_34.jpg'
                },
                {
                    original: './images/portfolio/residential/adanac/true_home_adanac_35.jpg'
                },
                {
                    original: './images/portfolio/residential/adanac/true_home_adanac_36.jpg'
                },
                {
                    original: './images/portfolio/residential/adanac/true_home_adanac_37.jpg'
                },
                {
                    original: './images/portfolio/residential/adanac/true_home_adanac_38.jpg'
                },
                {
                    original: './images/portfolio/residential/adanac/true_home_adanac_39.jpg'
                },
            ]
        },
        {
            title: "Coronation Ave",
            // subtitle: "A store front and interior renovation for a local Victoria business",
            // description: "This project consisted of several different components, both interior and exterior. Inside we installed new interior doors throughout, followed by new engineered maple hardwood throughout the lower level and on all the stairs treads and risers leading to the upstairs. At the same time, we redesigned the banister in fir with a housed stinger. And Lastly, we installed all new window and door casings, and baseboard. On the exterior we installed all new vinyl windows, and sliding glass doors, along with new trim around each. And out of pressure treated lumber we designed and built a new front porch, doubling it in size.",
            gallery: [
                {
                    original: './images/portfolio/residential/coronation_ave/true_home_coronation_ave_1.jpg'
                },
                {
                    original: './images/portfolio/residential/coronation_ave/true_home_coronation_ave_18.jpg'
                },
                {
                    original: './images/portfolio/residential/coronation_ave/true_home_coronation_ave_19.jpg'
                },
                {
                    original: './images/portfolio/residential/coronation_ave/true_home_coronation_ave_20.jpg'
                },
                {
                    original: './images/portfolio/residential/coronation_ave/true_home_coronation_ave_2.jpg'
                },
                {
                    original: './images/portfolio/residential/coronation_ave/true_home_coronation_ave_3.jpg'
                },
                {
                    original: './images/portfolio/residential/coronation_ave/true_home_coronation_ave_4.jpg'
                },
                {
                    original: './images/portfolio/residential/coronation_ave/true_home_coronation_ave_5.jpg'
                },
                {
                    original: './images/portfolio/residential/coronation_ave/true_home_coronation_ave_6.jpg'
                },
                {
                    original: './images/portfolio/residential/coronation_ave/true_home_coronation_ave_7.jpg'
                },
                {
                    original: './images/portfolio/residential/coronation_ave/true_home_coronation_ave_8.jpg'
                },
                {
                    original: './images/portfolio/residential/coronation_ave/true_home_coronation_ave_9.jpg'
                },
                {
                    original: './images/portfolio/residential/coronation_ave/true_home_coronation_ave_10.jpg'
                },
                {
                    original: './images/portfolio/residential/coronation_ave/true_home_coronation_ave_11.jpg'
                },
                {
                    original: './images/portfolio/residential/coronation_ave/true_home_coronation_ave_12.jpg'
                },
                {
                    original: './images/portfolio/residential/coronation_ave/true_home_coronation_ave_13.jpg'
                },
                {
                    original: './images/portfolio/residential/coronation_ave/true_home_coronation_ave_14.jpg'
                },
                {
                    original: './images/portfolio/residential/coronation_ave/true_home_coronation_ave_15.jpg'
                },
                {
                    original: './images/portfolio/residential/coronation_ave/true_home_coronation_ave_16.jpg'
                },
                {
                    original: './images/portfolio/residential/coronation_ave/true_home_coronation_ave_17.jpg'
                },
                {
                    original: './images/portfolio/residential/coronation_ave/true_home_coronation_ave_21.jpg'
                },
                {
                    original: './images/portfolio/residential/coronation_ave/true_home_coronation_ave_22.jpg'
                },
            ]
        },
        {
            title: "Trestle Pl",
            // subtitle: "A store front and interior renovation for a local Victoria business",
            // description: "This project consisted of several different components, both interior and exterior. Inside we installed new interior doors throughout, followed by new engineered maple hardwood throughout the lower level and on all the stairs treads and risers leading to the upstairs. At the same time, we redesigned the banister in fir with a housed stinger. And Lastly, we installed all new window and door casings, and baseboard. On the exterior we installed all new vinyl windows, and sliding glass doors, along with new trim around each. And out of pressure treated lumber we designed and built a new front porch, doubling it in size.",
            gallery: [
                {
                    original: './images/portfolio/residential/trestle_pl/true_home_trestle_pl_1.jpg'
                },
                {
                    original: './images/portfolio/residential/trestle_pl/true_home_trestle_pl_3.jpg'
                },
                {
                    original: './images/portfolio/residential/trestle_pl/true_home_trestle_pl_4.jpg'
                },
                {
                    original: './images/portfolio/residential/trestle_pl/true_home_trestle_pl_6.jpg'
                },
                {
                    original: './images/portfolio/residential/trestle_pl/true_home_trestle_pl_7.jpg'
                },
                {
                    original: './images/portfolio/residential/trestle_pl/true_home_trestle_pl_8.jpg'
                },
                {
                    original: './images/portfolio/residential/trestle_pl/true_home_trestle_pl_9.jpg'
                },
                {
                    original: './images/portfolio/residential/trestle_pl/true_home_trestle_pl_16.jpg'
                },
                {
                    original: './images/portfolio/residential/trestle_pl/true_home_trestle_pl_17.jpg'
                },
                {
                    original: './images/portfolio/residential/trestle_pl/true_home_trestle_pl_18.jpg'
                },
                {
                    original: './images/portfolio/residential/trestle_pl/true_home_trestle_pl_19.jpg'
                },
                {
                    original: './images/portfolio/residential/trestle_pl/true_home_trestle_pl_20.jpg'
                },
                {
                    original: './images/portfolio/residential/trestle_pl/true_home_trestle_pl_21.jpg'
                },
                {
                    original: './images/portfolio/residential/trestle_pl/true_home_trestle_pl_22.jpg'
                },
                {
                    original: './images/portfolio/residential/trestle_pl/true_home_trestle_pl_23.jpg'
                },
                {
                    original: './images/portfolio/residential/trestle_pl/true_home_trestle_pl_24.jpg'
                },
                {
                    original: './images/portfolio/residential/trestle_pl/true_home_trestle_pl_25.jpg'
                },
                {
                    original: './images/portfolio/residential/trestle_pl/true_home_trestle_pl_26.jpg'
                },
                {
                    original: './images/portfolio/residential/trestle_pl/true_home_trestle_pl_27.jpg'
                },
                {
                    original: './images/portfolio/residential/trestle_pl/true_home_trestle_pl_29.jpg'
                },
                {
                    original: './images/portfolio/residential/trestle_pl/true_home_trestle_pl_30.jpg'
                },
            ]
        },
        {
            title: "Cedar Hill",
            gallery: [
                {
                    original: './images/portfolio/residential/cedar_hill/true_home_cedar_hill_1.jpg'
                },
                {
                    original: './images/portfolio/residential/cedar_hill/true_home_cedar_hill_20.jpg'
                },
                {
                    original: './images/portfolio/residential/cedar_hill/true_home_cedar_hill_19.jpg'
                },
                {
                    original: './images/portfolio/residential/cedar_hill/true_home_cedar_hill_21.jpg'
                },
                {
                    original: './images/portfolio/residential/cedar_hill/true_home_cedar_hill_2.jpg'
                },
                {
                    original: './images/portfolio/residential/cedar_hill/true_home_cedar_hill_3.jpg'
                },
                {
                    original: './images/portfolio/residential/cedar_hill/true_home_cedar_hill_4.jpg'
                },
                {
                    original: './images/portfolio/residential/cedar_hill/true_home_cedar_hill_5.jpg'
                },
                {
                    original: './images/portfolio/residential/cedar_hill/true_home_cedar_hill_6.jpg'
                },
                {
                    original: './images/portfolio/residential/cedar_hill/true_home_cedar_hill_7.jpg'
                },
                {
                    original: './images/portfolio/residential/cedar_hill/true_home_cedar_hill_8.jpg'
                },
                {
                    original: './images/portfolio/residential/cedar_hill/true_home_cedar_hill_9.jpg'
                },
                {
                    original: './images/portfolio/residential/cedar_hill/true_home_cedar_hill_10.jpg'
                },
                {
                    original: './images/portfolio/residential/cedar_hill/true_home_cedar_hill_11.jpg'
                },
                {
                    original: './images/portfolio/residential/cedar_hill/true_home_cedar_hill_12.jpg'
                },
                {
                    original: './images/portfolio/residential/cedar_hill/true_home_cedar_hill_13.jpg'
                },
                {
                    original: './images/portfolio/residential/cedar_hill/true_home_cedar_hill_14.jpg'
                },
                {
                    original: './images/portfolio/residential/cedar_hill/true_home_cedar_hill_15.jpg'
                },
                {
                    original: './images/portfolio/residential/cedar_hill/true_home_cedar_hill_16.jpg'
                },
                {
                    original: './images/portfolio/residential/cedar_hill/true_home_cedar_hill_17.jpg'
                },
                {
                    original: './images/portfolio/residential/cedar_hill/true_home_cedar_hill_18.jpg'
                },
                {
                    original: './images/portfolio/residential/cedar_hill/true_home_cedar_hill_23.jpg'
                },
                {
                    original: './images/portfolio/residential/cedar_hill/true_home_cedar_hill_24.jpg'
                },
                {
                    original: './images/portfolio/residential/cedar_hill/true_home_cedar_hill_25.jpg'
                },
            ]
        },
        {
            title: "Aloha",
            gallery: [
                {
                    original: './images/portfolio/residential/aloha/true_home_aloha_1.jpg'
                },
                {
                    original: './images/portfolio/residential/aloha/true_home_aloha_2.jpg'
                },
                {
                    original: './images/portfolio/residential/aloha/true_home_aloha_3.jpg'
                },
                {
                    original: './images/portfolio/residential/aloha/true_home_aloha_4.jpg'
                },
                {
                    original: './images/portfolio/residential/aloha/true_home_aloha_8.jpg'
                },
                {
                    original: './images/portfolio/residential/aloha/true_home_aloha_7.jpg'
                },
                {
                    original: './images/portfolio/residential/aloha/true_home_aloha_5.jpg'
                },
                {
                    original: './images/portfolio/residential/aloha/true_home_aloha_6.jpg'
                },
            ]
        },
        {
            title: "Wicklow",
            gallery: [
                {
                    original: './images/portfolio/residential/wicklow/true_home_wicklow_4.jpg'
                },
                {
                    original: './images/portfolio/residential/wicklow/true_home_wicklow_1.jpg'
                },
                {
                    original: './images/portfolio/residential/wicklow/true_home_wicklow_2.jpg'
                },
                {
                    original: './images/portfolio/residential/wicklow/true_home_wicklow_3.jpg'
                },
                {
                    original: './images/portfolio/residential/wicklow/true_home_wicklow_5.jpg'
                },
                {
                    original: './images/portfolio/residential/wicklow/true_home_wicklow_6.jpg'
                },
                {
                    original: './images/portfolio/residential/wicklow/true_home_wicklow_7.jpg'
                },
                {
                    original: './images/portfolio/residential/wicklow/true_home_wicklow_8.jpg'
                },
                {
                    original: './images/portfolio/residential/wicklow/true_home_wicklow_9.jpg'
                },
                {
                    original: './images/portfolio/residential/wicklow/true_home_wicklow_10.jpg'
                },
                {
                    original: './images/portfolio/residential/wicklow/true_home_wicklow_11.jpg'
                },
                {
                    original: './images/portfolio/residential/wicklow/true_home_wicklow_12.jpg'
                },
                {
                    original: './images/portfolio/residential/wicklow/true_home_wicklow_13.jpg'
                },
                {
                    original: './images/portfolio/residential/wicklow/true_home_wicklow_14.jpg'
                },
                {
                    original: './images/portfolio/residential/wicklow/true_home_wicklow_15.jpg'
                },
            ]
        },
    ]
}
