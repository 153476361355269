import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { CodIcon } from '../CodIcon';

class Section extends Component {

  static propTypes = {
    icon: PropTypes.string,
    iconStyle: PropTypes.object,
    title: PropTypes.string,
    body: PropTypes.string,
    link: PropTypes.string,
    sectionStyle: PropTypes.object,
    mini: PropTypes.bool,
    primaryColour: PropTypes.bool,
    secondaryColour: PropTypes.bool
  };

  render() {

    const {
      icon,
      iconStyle,
      title,
      body,
      link,
      sectionStyle,
      mini,
      primaryColour,
      secondaryColour,
      clubLink
    } = this.props;

    const sectionClass = classNames(
        'section',
        {
          'link': link,
          'mini': mini,
          'primary-colour': primaryColour,
          'secondary-colour': secondaryColour
        }
    );

    // Check Icon Type
    const iconSrc = () => {
        let iconType;
        // If SVG or PNG
        if (/(\.svg)$|(\.png)$|(\.jpg)$|(\.jpeg)$/.test(icon)) {
            iconType = <img className="icon" src={icon} style={iconStyle} />;
        } else if (/(\.cod)$/.test(icon)) {
          // If COD Icon
          iconType = (
            <CodIcon
              icon={icon}
              primaryColour={primaryColour}
              secondaryColour={secondaryColour}
            />
          );
        } else {
            // If Icon Font
            iconType = <i className="material-icons icon-graphic" style={iconStyle}>{icon}</i>;
        }
        return iconType;
    };

    // Handle if section is a link
    if (link) {
      return (
        <Link
          className={sectionClass}
          style={sectionStyle}
          to={link}
        >
          <header>
            {icon &&
              <figure className='top-graphic'>
                {iconSrc()}
              </figure>
            }
            {title &&
              <h4 className='title'>{title}</h4>
            }
          </header>
          {body &&
            <p className='body'>{body}</p>
          }
        </Link>
      )
  } else if (clubLink) {
      return (
        <div className={sectionClass} style={sectionStyle}>
          <header>
            {icon &&
              <figure className='top-graphic'>
                  <a
                      href={clubLink}
                      target="_blank"
                      style={{ cursor: 'pointer' }}
                  >
                    {iconSrc()}
                  </a>
              </figure>
            }
            {title &&
              <h4 className='title'>{title}</h4>
            }
          </header>
          {body &&
            <p className='body'>{body}</p>
          }
      </div>
      )
    } else {
      return (
        <div className={sectionClass} style={sectionStyle}>
          <header>
            {icon &&
              <figure className='top-graphic'>
                {iconSrc()}
              </figure>
            }
            {title &&
              <h4 className='title'>{title}</h4>
            }
          </header>
          {body &&
            <p className='body'>{body}</p>
          }
      </div>
      )
    }

  }
}

export default Section;
