import React, { Component } from 'react';

import { Button } from '../Buttons';
import ContactForm from './ContactForm';

class ContactButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  toggle = () => {
    this.setState({
      open: !this.state.open
    });
  }

  render() {

    const {
      phone,
      email,
      form
     } = this.props;

    const emailLink = "mailto:" + email;

    if (form) {
      return (
        <aside className='contact-action'>
          {this.state.open &&
            <ContactForm
              email={email}
              phone={phone}
              colour
            />
          }

          {this.state.open ?
            <Button
              type='solid'
              icon='close'
              className='contact-button'
              circle
              onClick={this.toggle}
            />
          :
            <Button
              type='solid'
              icon='mail_outline'
              className='contact-button'
              circle
              onClick={this.toggle}
            />
          }
        </aside>
      );
    } else {
      return (
        <aside className='contact-action'>
          <Button
            type='solid'
            icon='mail_outline'
            className='contact-button'
            circle
            href={emailLink}
          />
        </aside>
      );
    }
  }
}

export default ContactButton;
