import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button } from '../Buttons';

class BottomNav extends Component {

  static propTypes = {
    build: PropTypes.object
  };

  render() {

    const { build } = this.props;

    return (
      <footer className='bottom-nav'>
        <div className='address'>{build.address}</div>
        <div className='cod'>
          <Button
            small
            light
            type="line"
            className="cod-promo"
            href='http://www.truehome.ca/'
          >
            True Home<sup>&#174;</sup> Group
          </Button>
          <Button
            small
            light
            type="line"
            className="cod-promo"
            href='/careers'
            style={{ margin: "16px" }}
          >
            careers
          </Button>
        </div>
        <div className='social'>
            <a
              href="https://search.google.com/local/writereview?placeid=ChIJg7_CuUltj1QRzo_EHz4MMlI"
              target="_blank"
            >
                <i className="fa fa-google-plus-square" aria-hidden="true" />
            </a>
          {build["social"]["facebook"] &&
            <a href={build["social"]["facebook"]} target="_blank">
              <i className="fa fa-facebook-official" aria-hidden="true" />
            </a>
          }
          {build["social"]["twitter"] &&
          <a href={build["social"]["twitter"]}>
            <i className="fa fa-twitter-square" aria-hidden="true" />
          </a>
          }
          {build["social"]["instagram"] &&
          <a
            href={build["social"]["instagram"]}
            target="_blank"
          >
            <i className="fa fa-instagram" aria-hidden="true" />
          </a>
          }
          {build["social"]["yp"] &&
          <a href={build["social"]["yp"]}>
            <i className="fa fa-yelp" aria-hidden="true" />
          </a>
          }
        </div>
      </footer>
    );
  }
}

export default BottomNav;
