import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import { Billboard } from '../Billboard';

class Page404 extends Component {
  render() {
    return (
      <div className='main-content'>
        <Helmet>
            <meta name="description" content="Sorry, but this page doesn't exist yet." />
            <title>Page Not Found</title>
        </Helmet>

        <Billboard
          horizontal="center"
          vertical="middle"
          width="half"
          colour="dark"
          alignText="center"
          mini
        >
          <h1>404</h1>
          <p>Sorry, but this page doesn't exist yet.</p>
        </Billboard>
      </div>
    );
  }
}

export default Page404;
