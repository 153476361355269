import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Paper from 'material-ui/Paper';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

class Storyboard extends Component {

  static propTypes = {
    title: PropTypes.string,
    body: PropTypes.string,
    overlapTop: PropTypes.bool,
    overlapBottom: PropTypes.bool
  };

  render() {

    const {
      title,
      body,
      overlapTop,
      overlapBottom
    } = this.props;

    const storyboardClass = classNames(
        'storyboard-container',
        {
          'overlap-top': overlapTop,
          'overlap-bottom': overlapBottom
        }
    );

    const paperStyle = {
      width: 'calc(100% - 32px)',
      margin: '0 16px',
      padding: 0,
      textAlign: 'left',
      position: 'relative',
      display: 'inline-block'
    };

    return (
      <section className={storyboardClass}>
        <div className="storyboard">
          <MuiThemeProvider>
            <Paper style={paperStyle} zDepth={3}>
              <div className="story">
                <header>
                    <h2 className="title">True Home<sup>&#174;</sup> Construction in Victoria, BC</h2>
                </header>
                <p>
                  Welcome to True Home<sup>&#174;</sup> Construction, where we pride ourselves in superior craftmanship, customer service, and a finished product that yields consistently satisfied clients.
                  <br /><br />
                  With years of faithful service to Greater Victoria, True Home<sup>&#174;</sup> has established lasting relationships with local suppliers and professional trades alike, ensuring consistency and excellence to our projects.  In an ever-changing industry, our adaptability and willingness to innovate has made us a respected company in the community, garnering highly positive responses from clients whose expectations we continue to exceed. Whether it’s a small renovation or the construction of your house from the ground up, trust a little company to make a big difference—let us turn your house into your True Home<sup>&#174;</sup>.</p>
              </div>
            </Paper>
          </MuiThemeProvider>
        </div>
      </section>
    );
  }
}

export default Storyboard;
