import React from 'react';

const IconTextButton = (props) => {

    // Props
    const {
        children,
        className,
        href,
        icon,
        onClick,
        target,
        disabled,
        dataValue,
        style
    } = props;

    // Check Icon Type
    const iconSrc = () => {
        let iconType;
        // If SVG or PNG
        if (/(\.svg)$|(\.png)$/.test(icon)) {
            iconType = <img className="icon" src={icon} />;
        } else {
            // If Icon Font
            iconType = <i className="icons">{icon}</i>;
        }
        return iconType;
    };

    // Create Element
    if (href) {
        return(
            <a href={href} className={className} onClick={onClick} target={target} rel="noreferrer noopener" data-value={dataValue} style={style}>
                {iconSrc()}
                {children}
            </a>
        );
    }
    else {
        return (
            <button disabled={disabled} className={className} onClick={onClick} data-value={dataValue} style={style}>
                {iconSrc()}
                {children}
            </button>
        );
    }
};

export default IconTextButton;
