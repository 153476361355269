import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TextButton from './TextButton';
import IconButton from './IconButton';
import IconTextButton from './IconTextButton';

class Button extends Component {

    // PropTypes
    static propTypes = {
        circle: PropTypes.bool,
        children: PropTypes.node,
        className: PropTypes.string,
        href: PropTypes.string,
        icon: PropTypes.string,
        iconAlign: PropTypes.oneOf([
            'left',
            'right',
        ]),
        onClick: PropTypes.func,
        small: PropTypes.bool,
        light: PropTypes.bool,
        disabled: PropTypes.bool,
        target: PropTypes.oneOf([
            '_blank',
            '_parent',
            '_self',
            '_top',
        ]),
        style: PropTypes.object,
        type: PropTypes.oneOf([
            'line',
            'quiet',
            'solid',
            'no-effect',
        ]).isRequired,
        dataValue: PropTypes.string
    };

    handleClick = (e) => {
        const { disabled, onClick } = this.props;
        if (disabled) {
            e.preventDefault();
            return;
        }
        if (onClick) onClick(e);
    }

    render() {

        // Props
        const {
            circle,
            children,
            className,
            icon,
            iconAlign,
            target,
            type,
            small,
            light,
            style,
            href,
            disabled,
            dataValue
        } = this.props;

        const componentClass = classNames(
            // Button Types and Styles
            'btn ' + type,
            {
                'icon': icon && !children,
                'icon-text': icon && children,
                'icon-left': iconAlign == 'left',
                'icon-right': iconAlign == 'right',
                'circle': circle,
                'small': small,
                'light': light,
                'link': href
            },
            className
        );

        // Create Element
        if (children && !icon) {
            return (
                <TextButton disabled={disabled} href={href} target={target} className={componentClass} onClick={this.handleClick} dataValue={dataValue} style={style}>
                    {children}
                </TextButton>
            );
        }
        if (!children && icon) {
            return (
                <IconButton disabled={disabled} icon={icon} href={href} target={target} className={componentClass} onClick={this.handleClick} dataValue={dataValue} style={style} />
            );
        }
        if (children && icon) {
            return (
                <IconTextButton disabled={disabled} icon={icon} href={href} target={target} className={componentClass} onClick={this.handleClick} dataValue={dataValue} style={style}>
                    {children}
                </IconTextButton>
            );
        }
    }
};

export default Button;
