import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import { Billboard } from '../Billboard';
import ImageGallery from 'react-image-gallery';

import { residential } from '../../residential';
import { commercial } from '../../commercial';

class Portfolio extends Component {
  render() {
    const { build } = this.props;

    const residentialPortfolio = [];
    const commercialPortfolio = [];

    residential.forEach((project) => {
        // <div className="info-container">
        //   <h2 className="project-title">{project.title}</h2>
        //   {project.subtitle && <h3 className="project-subtitle">{project.subtitle}</h3>}
        //   {project.description && <p className="project-description">{project.description}</p>}
        // </div>
        residentialPortfolio.push((
          <div className="gallery-container">
              <ImageGallery
                  items={project.gallery}
                  showThumbnails={false}
                  className="gallery-container"
                  showIndex
                  // lazyLoad={true}
              />
          </div>
        ));
    });

    commercial.forEach((project) => {
        // <div className="info-container">
        //   <h2 className="project-title">{project.title}</h2>
        //   {project.subtitle && <h3 className="project-subtitle">{project.subtitle}</h3>}
        //   {project.description && <p className="project-description">{project.description}</p>}
        // </div>
        commercialPortfolio.push((
          <div className="gallery-container">
              <ImageGallery
                  items={project.gallery}
                  showThumbnails={false}
                  className="gallery-container"
                  showIndex
                  // lazyLoad={true}
              />
          </div>
        ));
    });

    const metaDescription = `${build["name"]} portfolio.`;
    const title = `${build["name"]} portfolio.`;

    return (
      <div className="main-content">
        <Helmet>
            <meta name="description" content={metaDescription} />
            <meta name="keywords" content={build["name"]} />
            <title>{title}</title>
        </Helmet>

        <Billboard
          image="./images/backgrounds/portfolio.jpg"
          horizontal="center"
          vertical="middle"
          width="three-quarters"
          colour="light"
          alignText="center"
          mini
          className="portfolio-board"
        >
          <h1>
            {build["name"]}
            <br/>
            Portfolio
          </h1>
        </Billboard>

        <div className="portfolio-container">
            <div className="info-container">
                <h2 className="project-title">Residential</h2>
            </div>
            {residentialPortfolio}
            <div className="info-container">
                <h2 className="project-title">Commercial</h2>
            </div>
            {commercialPortfolio}
        </div>
      </div>
    );
  }
}

export default Portfolio;
