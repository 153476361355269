import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import { Button } from '../Buttons';

class Pages extends Component {

  render() {
    const { pages } = this.props;

    const listItems = pages.map((page) => {
      let itemLink = '/' + page.split(' ').join('-').toLowerCase();

      return (
        <li>
          <NavLink
            to={itemLink}
            activeClassName="selected"
            >
              <Button
                small
                light
                type="quiet"
              >{page}</Button>
            </NavLink>
        </li>
      );
    });

    return (
        <div className="pages">
          <ul>
            {listItems}
          </ul>
        </div>
    );
  }
}

export default Pages;
