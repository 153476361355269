import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import Drawer from 'material-ui/Drawer';
import MenuItem from 'material-ui/MenuItem';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

import { Button } from '../Buttons';

class MobileMenu extends Component {

  constructor(props) {
    super(props);
    this.state = {drawerOpen: false};
  }

  handleDrawerToggle = () => this.setState({drawerOpen: !this.state.drawerOpen});
  handleDrawerClose = () => this.setState({drawerOpen: false});

  render() {
    const {pages} = this.props;

    const drawerItems = pages.map((page) => {
      let itemLink = '/' + page.split(' ').join('_').toLowerCase();

      return (
        <NavLink
            to={itemLink}
            activeClassName="selected"
            >
          <MenuItem
            onTouchTap={this.handleDrawerClose}
            onClick={this.handleDrawerClose}
          >
            {page}
          </MenuItem>
        </NavLink>
      );
    });

    return (
      <MuiThemeProvider>
        <div className="mobile-menu">
          <Button
            small
            light
            type="quiet"
            className="call-icon"
            onClick={this.handleDrawerToggle}
            icon="menu"
          />
          <Drawer
            docked={false}
            width={200}
            open={this.state.drawerOpen}
            onRequestChange={(drawerOpen) => this.setState({drawerOpen})}
          >
            {drawerItems}
          </Drawer>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default MobileMenu;
