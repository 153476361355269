import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import { Billboard } from '../Billboard';
import { ContactForm } from '../ContactForm';
import { Map } from '../Map';

class Contact extends Component {

  render() {
    const build = this.props.build;

    const metaDescription = `Contact ${build["name"]}.`;
    const title = `Contact ${build["name"]}`;

    const phoneLink = 'tel:+1' + build["phone"].split(' ').join('');
    const emailLink = "mailto:" + build["email"];

    return (
      <div className="main-content">
        <Helmet>
            <meta name="description" content={metaDescription} />
            <meta name="keywords" content={`contact ${build["name"]}`} />
            <title>{title}</title>
        </Helmet>


        <Billboard
          image="./images/backgrounds/contact.jpg"
          horizontal="center"
          vertical="middle"
          width="three-quarters"
          colour="dark"
          alignText="left"
          mini
          className="contact-board"
        >
            <h1>
              We are here for all of your construction needs.
              <br/>
              Contact us today!
            </h1>
            <p>Give us a call or use the form below to get a hold of us.</p>
        </Billboard>

        <div className="contact-info">
          <div className="text-info">
            <span className="info-title" style={{ display: "block", marginBottom: "12px" }}>Call us</span>
            <a href={phoneLink} style={{ display: "block", marginBottom: "8px" }}>Paul Greenwood: +1 {build["phone"]}</a>
            <a href="tel:+12505169046" style={{ display: "block", marginBottom: "32px" }}>Ian Greenwood: +1 250 516 9046</a>
            <span className="info-title">Email us</span>
            <a href={emailLink}>{build["email"]}</a>
          </div>

        </div>

        {/* <ContactForm
            phone={build["phone"]}
            email={build["email"]}
          >
          <p>
            We will respond within <b>24hr</b> during the business week.
            <br/>
            You can also request a callback or contact us by sending us an email.
            <br/>
            Please don't forget to <b>include your phone number</b> if you would like for us to call you back.
          </p>
        </ContactForm> */}

        <ContactForm
          phone={build["phone"]}
          email={build["email"]}
          buttonsOnly
        >
          <p>
            We will respond within <b>24hr</b> during the business week.
            <br/>
            You can also request a callback or contact us by sending an email.
            <br/>
            Please don't forget to <b>include your phone number</b> if you would like for us to call you back.
          </p>
        </ContactForm>

        <div className="address-info">
          <h3 className="map-title">Find us in {build["location"]} BC</h3>
          <i className="material-icons icon-graphic location">location_on</i>
        </div>
        {/*<div className="contact-info">
           <span className="info-title">{build["address"]}</span>
        </div>*/}

        <div className="map-display">
          <Map
            link={build["homepage"]["map"]["link"]}
            apiKey={build["homepage"]["map"]["apiKey"]}
          />
        </div>

        <div className="address-info" style={{ marginBottom: "56px", margin: "16px 16px 56px" }}>
          <h3 className="map-title">Interested in joining our crew? Checkout our <a href="/careers" style={{ cursor: "pointer", textDecoration: "underline", fontSize: "24px", fontWeight: 300 }}>careers page</a></h3>
        </div>
      </div>
    );
  }
}

export default Contact;
