import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import { Billboard } from '../Billboard';

class Reviews extends Component {
  render() {
    const { build } = this.props;

    const metaDescription = `${build["name"]}'s reviews and testimonials.`;
    const title = `${build["name"]}'s reviews and testimonials`;

    return (
      <div className="main-content">
        <Helmet>
            <meta name="description" content={metaDescription} />
            <meta name="keywords" content={build["name"]} />
            <title>{title}</title>
        </Helmet>

        <Billboard
          image="./images/backgrounds/reviews.jpg"
          horizontal="center"
          vertical="middle"
          width="three-quarters"
          colour="light"
          alignText="center"
          mini
          className="about-board"
        >
            <h1>Checkout out what our clients have to say about us</h1>
        </Billboard>

        <div className="review-container" id="borys">
            <div className="personal-container">
              <span
                className="name"
              >
                T.Borys & J.Brown
              </span>
              <span
                className="date"
              >
                March 2015
              </span>
            </div>
            <div className="review-body">
              <p className="review-text">
                After spending January, February, and March 2015 on Vancouver Island to escape a Winnipeg prairie winter, we made a “snap decision” to purchase a 1600 sq. ft. condo in Victoria.  An excellent local realtor helped us select an appropriate property even though we realized that considerable work would be required to enhance the 18-year old unit.
                <br /><br />
                With a quick purchase on March 13 and an April 1 possession date, we immediately began the search for a general contractor to help us complete our renovation dreams for our new home.  Without time to spare, we had the very good fortune of meeting Paul Greenwood near the end of March 2015. Paul did magic and made it all happen for our June 2 arrival to live in our new home.
                <br /><br />
                Our renovation was a major project.  While our strata is a highly desirable complex in the Broadmead area of Saanich, there had been very few updates in the unit since its construction in 1998.  Accordingly, our intent was to do a complete makeover with modern finishes as recommended by our interior designer back at home.
                <br /><br />
                Paul visited the condo with us and prepared detailed information for us to study in his initial quotation for the scope of work requested.  We were highly impressed with his attention to detail in this initial aspect of his work, which served us well through the entire project.  Some quick reference checks assisted in knowing that Paul was the right general contractor for this project and our required time-lines.  We hired Paul, drove back to Winnipeg, sold our home, and prepared to move to Victoria in late May.
                <br /><br />
                As promised, Paul began his work on April 1, possession date.  From the very onset, Paul communicated regularly by email, “telephone meetings”, or text message – frequently sending me photos of the work in progress.  As often happens, we thought of additional betterments for the property as we reflected on the purchase.  Paul was always quick to cost these possibilities and open to adjusting his scope of work to incorporate our new ideas.  His desire to collaborate effectively with us (and our interior designer in Winnipeg) was evident from the onset of the project.
                <br /><br />
                We found Paul to be professional, reliable, hard-working, and highly skilled.  He quickly assembled his strong team of tradespeople for the components of the project.  Indeed, when we arrived in Victoria on June 2, we had the opportunity to observe Paul in action, to watch the “finishing touches” completed on our new home, and to meet many of these professionals who provided their expertise to the project as they came to finish touch-ups, details, or minor additional work requested after our arrival.  Paul and his team were professional, courteous, attentive to detail, sincerely wanting to please, and very well-organized.
                <br /><br />
                This was a “whirlwind experience” for us as we had not planned to move to Victoria when we came to the Island on December 31, 2014 for a three-month stay.  If the renovations went well, it is due to the work ethic, professionalism, and collaboration of Paul Greenwood.  We believe that we “lucked out” the day we met him in late March 2015.  Two months after moving into our new home, we are 100% satisfied with the work that Paul did for us in this major renovation.  We would not hesitate to hire him again or to recommend him to others.  It’s also great to know that Paul and his team are only a text message away for any of our future needs.
              </p>
            </div>
        </div>

        <div className="review-container" id="cameron">
            <div className="personal-container">
              <span
                className="name"
              >
                D. & I.Cameron
              </span>
              <span
                className="date"
              >
                June 2014
              </span>
            </div>
            <div className="review-body">
              <p className="review-text">
                We have known Paul Greenwood for a number of years, so when it came time to do a major and rather complicated renovation on our house this past summer, we knew he would be the man for the job.  We were not disappointed.  The renovation consisted of coordinating various trades to work inside as well as outside the house.  Both our upstairs bathrooms and master bedroom were totally demolished and re-configured, with new windows, tile, vanities, hardware, etc.   We had new flooring laid in two bedrooms,  new windows installed throughout the whole house; new laundry and powder room downstairs and new interior doors and trim throughout.  On the outside of the house, we had a new layer of stucco applied to the whole house.  The old stucco was cracking and damaged, so rather than painting, Paul suggested that we go with a new coating of stucco.  This new layer was done in a different texture and colour to the old stucco, giving the house a more modern look.  Paul also bulked up the front porch posts, giving them a more interesting look.  All the new interior walls and trim, and the exterior trim and garage doors were painted.  Paul demolished our existing deck and re-built a beautiful cedar deck and steps, then covered half the deck with a gabled cedar pergola.
                <br /><br />
                All this was done with us still living in the house.  We were always treated with respect and courtesy by Paul and his team of tradesmen, all of whom are perfectionists,  well qualified and fastidious with their work.  Knowing that we were living in the house during the construction, the site was always kept clean and tidy.
                <br /><br />
                Paul listened to our ideas, gave us solutions to possible dilemmas, made sound suggestions, was prompt and attentive and very honest.  We totally put our trust in him, and felt comfortable doing so.  We would highly recommend Paul and his team at True Home® Construction and will certainly employ his expertise again in the future.
              </p>
            </div>
        </div>

        <div className="review-container" id="frank">
            <div className="personal-container">
              <span
                className="name"
              >
                Erin Frank
              </span>
              <span
                className="date"
              >
                August 2013
              </span>
            </div>
            <div className="review-body">
              <p className="review-text">
                Just over a year ago, when I made my very first real estate purchase, True Home® Construction was recommended to me by my Realtor® (a colleague of Paul’s) for the renovations. I had been renting for so many years and this was a very exciting time for me! The house I bought was great, but was definitely in desperate need of updating. A wall needed to be removed to expand the living room and let light in, other rooms needed to be built to fit my family, and the kitchen needed to be completely re-done. Finding a contractor for these kinds of things can be more than a little stressful unless you know people in the business who will point you in the right direction… and I am so glad that True Home® Construction was recommended to me! Paul Greenwood is honest and trustworthy, as well as being incredibly knowledgeable and skilled and he went out of his way to help me transform the outdated space into the exact vision I had for the home I wanted to live in. I will hire True Home® Construction for any future work I need done and wouldn’t hesitate to refer anyone to Paul for their own homes.
                <br /><br />
                I would also like to add that Team True Home® 2013 participated in the Easter Seals 24 Hour Relay to raise money for kids with disabilities - which is particularly heart-warming to me because a member of my very own family has special needs and has attended the Easter Seals Camp in Shawnigan Lake. My family knows first-hand how very important it is that people who have disabilities get the support they need, and I'm very proud of Paul's involvement in the 24 Hr Relay.
              </p>
            </div>
        </div>
      </div>
    );
  }
}

export default Reviews;
