import React from 'react';

const TextButton = (props) => {

    // Props
    const {
        children,
        className,
        href,
        onClick,
        target,
        disabled,
        dataValue,
        style
    } = props;

    // Create Element
    if (href) {
        return(
            <a href={href} className={className} onClick={onClick} target={target} rel="noreferrer noopener" data-value={dataValue} style={style}>
                {children}
            </a>
        );
    }
    else {
        return (
            <button disabled={disabled} className={className} onClick={onClick} data-value={dataValue} style={style}>
                {children}
            </button>
        );
    }
};

export default TextButton;
