import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { SectionsContainer, Section } from '../Sections';

class Clubs extends Component {

  static propTypes = {
    bbb: PropTypes.bool,
    bccc: PropTypes.bool,
    bni: PropTypes.bool,
    cca: PropTypes.bool
  };

  render() {

    const {
      bbb,
      bccc,
      bni,
      cca,
      hpo,
      nhm,
      shoplocal,
      redseal
    } = this.props;

    const style = {
      border: 'none',
      height: '80px',
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    };

    return (
      <section className='clubs'>
        <SectionsContainer>
          {bbb &&
            <Section
              icon='./images/clubs/bbb.jpg'
              iconStyle={{width: '180px'}}
              sectionStyle={style}
              clubLink="https://www.bbb.org/ca/bc/victoria/profile/general-contractor/true-home-construction-0047-90006649 "
            />
          }
          {bccc &&
            <Section
              icon='./images/clubs/bccc.jpg'
              iconStyle={{width: '200px'}}
              sectionStyle={style}
            />
          }
          {bni &&
            <Section
              icon='./images/clubs/bni.png'
              iconStyle={{width: '130px'}}
              sectionStyle={style}
            />
          }
          {cca &&
            <Section
              icon='./images/clubs/cca.png'
              iconStyle={{width: '200px'}}
              sectionStyle={style}
            />
          }
          {hpo &&
            <Section
              icon='./images/clubs/hpo.jpg'
              iconStyle={{width: '180px'}}
              sectionStyle={style}
              clubLink="https://www.bchousing.org/licensing-consumer-services"
            />
          }
          {nhm &&
            <Section
              icon='./images/clubs/nhm.png'
              iconStyle={{width: '200px'}}
              sectionStyle={style}
              clubLink="https://www.nationalhomewarranty.com/"
            />
          }
          {shoplocal &&
            <Section
              icon='./images/clubs/shoplocal.jpg'
              iconStyle={{width: '120px'}}
              sectionStyle={style}
              clubLink="https://thinklocalvictoria.com/"
            />
          }
          {redseal &&
            <Section
              icon='./images/clubs/red_seal.jpg'
              iconStyle={{width: '70px'}}
              sectionStyle={style}
              clubLink="https://www.itabc.ca/red-seal-program"
            />
          }
        </SectionsContainer>
      </section>
    );
  }
}

export default Clubs;
